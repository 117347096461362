const DATA = [
  {
    instruction: "Please say the formula after your answer. Thanks.",
    text: () => "What can you tell me about the Pythagorean Theorem",
    skipValidation: true,
  },
  {
   text: () => "What can you tell me about the interior angles of a triangle?",
   skipValidation: true,
  },
  {
   text: () => "What can you tell me about the interior angles of a square?",
   skipValidation: true,
  },
  {
    text: () => "What is the fundamental theorem of arithmetic?",
    skipValidation: true,
  },
  {
    text: () => "Is 83 a prime number (or) Is 83 a product of prime factors?",
    skipValidation: true,
  },
  {
    text: () => "Which side (a., b., or c.) is adjacent theta?",
    image: "001",
    skipValidation: true,
  },
  {
    text: () => "Which side (a., b., or c.) is opposite theta?",
    image: "001",
    skipValidation: true,
  },
  {
    text: () => "Which side (a., b., or c.) is hypotenuse?",
    image: "001",
    skipValidation: true,
  },
  {
    text: () => "Please answer the following questions as numbers. Try to use your head but use scratch paper if you must.",
    skipValidation: true,
  },
  {
    text: () => "What is the cubed root of 64?",
    skipValidation: false,
    answer: 4
  },
  {
    text: () => "What is the cubed root of 1,000,000?",
    skipValidation: false,
    answer: 100
  },
  {
    text: () => "What is the cubed root of 1,000,000,000?",
    skipValidation: false,
    answer: 1000
  },
  {
    text: () => "How long is the hypotenuse?",
    image: "002-1",
    skipValidation: false,
    answer: 50,
  },
  {
    instruction: "Number only",
    text: () => "If Angle B = 45 deg., what does Angle A equal (in deg.)?",
    image: "003",
    skipValidation: false,
    answer: 45,
  },
  {
    instruction: "Number only",
    text: () => "If Angle B = 30 deg., what does Angle A equal (in deg.)?",
    image: "004",
    skipValidation: false,
    answer: 60,
  },
  {
    text: () => "What’s the perimeter of this triangle?",
    image: "002-1",
    skipValidation: false,
    answer: 120,
  },
  {
    instruction: "Answer can include \“pi\”",
    text: () => "What’s the circumference of the circle below?",
    image: "006-1",
    skipValidation: true,
  },
  {
    text: () => "What is the answer?",
    image: "007",
    skipValidation: false,
    answer: 1,
  },
  {
    text: () => "What is the answer?",
    image: "009-1",
    skipValidation: false,
    answer: 1000000,
  },
  {
    text: () => "What is the answer?",
    image: "010-1",
    skipValidation: false,
    answer: 25,
  },
  {
    instruction: "Decimals only and no fraction",
    text: () => "What is the answer?",
    image: "011",
    skipValidation: false,
    answer: 0.001,
  },
  {
    text: () => "What is X?",
    image: "012",
    skipValidation: false,
    answer: 14,
  },
  {
    text: () => "What is X?",
    image: "013-1",
    skipValidation: false,
    answer: 11,
  },
  {
    text: () => "What is X?",
    image: "014-1",
    skipValidation: false,
    answer: 5,
  },
  {
    text: () => "What is X?",
    image: "015",
    skipValidation: false,
    answer: 5,
  },
  {
    text: () => "What is X?",
    image: "016",
    skipValidation: false,
    answer: 5,
  },
  {
    text: () => "What is X?",
    image: "017",
    skipValidation: false,
    answer: 215,
  },
  {
    text: () => "What is the answer?",
    image: "019",
    skipValidation: false,
    answer: 435,
  },
  {
    text: () => "What is the answer?",
    image: "020",
    skipValidation: false,
    answer: 108,
  },
  {
    text: () => "What is the answer?",
    image: "021",
    skipValidation: false,
    answer: 7,
  },
  {
    instruction: "Answer in the following format \“1 by 4\”",
    text: () => "What is the answer?",
    image: "022-1",
    skipValidation: false,
    answer: "7/12",
  },
]

export default DATA;