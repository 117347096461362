import {useState, useEffect} from 'react';
import {
	RootContainer,
	Header,
	HeaderImage,
	ListContainer,
	ListItem,
	ListItemInnerContainer,
	ListTitle,
	ListSubtitle,
} from './Dashboard.styles';
import Logo from 'assets/images/brainsprays_logo400.png';
import {PrimaryButton, VerticalSpace, HorizontalSpace, Modal} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';
import {useParams, useNavigate} from 'react-router-dom';
import moment from 'moment';

const Dashboard = () => {
	const dispatch = useDispatch();
	const {allAnswers, accountId} = useSelector(state => state.user, shallowEqual)
	const navigate = useNavigate();

	useEffect(() => {
		if(!accountId) {
			navigate('/')
		}
		else
			dispatch(userActions.getDashboardDataAsyncActions.request(accountId))
	}, [])

	return (
		<RootContainer>
			<Header>
				<HeaderImage src={Logo} />
				<PrimaryButton onClick={() => navigate('/library')} >Start New Practice →</PrimaryButton>
			</Header>
			<VerticalSpace count={5} />
			<ListContainer>
				{
					allAnswers.map((answer, index) => (
						<ListItem key={answer._id} >
							<ListItemInnerContainer>
								<ListTitle>Recording {index+1}</ListTitle>
								<ListSubtitle>{moment(answer.createdOn).format("DD-MMM-YYYY")}</ListSubtitle>
							</ListItemInnerContainer>
							<ListItemInnerContainer>
								<a href={answer.objectURL} target="_blank" >PPT Link</a>
							</ListItemInnerContainer>
							<ListItemInnerContainer>
								<audio src={answer.audioUrl} controls />
							</ListItemInnerContainer>
						</ListItem>
					))
				}
			</ListContainer>
		</RootContainer>
	)
}

export default Dashboard;