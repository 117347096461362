import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Routes
} from "react-router-dom";
import {
	Instructions, 
	Interview, 
	Opening, 
	Thanks,
	Dashboard,
	Library
} from 'screens';

const RootRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path='/instructions/:index' element={<Instructions />} />
				<Route path='/interview/:index' element={<Interview />} />
				<Route path='/thanks/' element={<Thanks />} />
				<Route path='/library' element={<Library />} />
				<Route path='/dashboard' element={<Dashboard />} />
				<Route path='/' element={<Opening />} />
			</Routes>
		</Router>
	)
}

export default RootRouter;