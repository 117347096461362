import {
	RootContainer,
	Title,
	Subtitle,
	InstructionsContainer,
	LibraryCard,
} from './Library.styles';
import {
	VerticalSpace,
	PrimaryButton,
} from 'components';
import {useNavigate} from 'react-router-dom';
import challenges from 'assets/challenges';

const Library = ({screen}) => {
	const navigate = useNavigate()
	return (
		<RootContainer>
			<Title>Library</Title>
			<Subtitle>Select a challenge to continue</Subtitle>
			<VerticalSpace count={2} />
			<InstructionsContainer>
				{
					challenges.map((challenge, index) => <LibraryCard onClick={() => navigate(`/instructions/${index}`)} >{challenge.title}</LibraryCard>)
				}
			</InstructionsContainer>
			<VerticalSpace count={2} />
		</RootContainer>
	)
}

export default Library;