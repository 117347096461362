import challengeFeb14 from './Challenge-feb-14-2022';
import challengeFeb17 from './Challenge-feb-17-2022';
import challengeFeb23 from './Challenge-feb-23-2022';
import challengeFeb28_1 from './Challenge1-feb-28-2022';
import challengeFeb28_2 from './Challenge2-feb-28-2022';
import challengeMar3 from './Challenge-mar-3-2022';
import challengeMar7 from './Challenge-mar-7-2022';

const challengesArray = [
	{
		title: "Feb 14 2022 Challenge",
		question: challengeFeb14
	},
	{
		title: "Feb 17 2022 Challenge",
		question: challengeFeb17
	},
	{
		title: "Feb 23 2022 Challenge",
		question: challengeFeb23
	},
	{
		title: "Feb 28 2022 Challenge 1",
		question: challengeFeb28_1
	},
	{
		title: "Feb 28 2022 Challenge 2",
		question: challengeFeb28_2
	},
	{
		title: "Mar 3 2022 Challenge",
		question: challengeMar3
	},
	{
		title: "Mar 7 2022 Challenge",
		question: challengeMar7
	},
]

export default challengesArray;