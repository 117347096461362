import styled from 'styled-components';

export const RootContainer = styled.div`
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Header = styled.div`
	min-width: 80%;
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 30px;
`;

export const HeaderImage = styled.img`
	width: 60px;
	height: auto;
`;

export const ListContainer = styled.div`
	width: 90vw;
`;

export const ListItem = styled.div`
	padding:10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:nth-child(even) {
		background-color: #eee;
	}
`;

export const ListItemInnerContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ListTitle = styled.div`
	font-size: 1.2em;
	font-weight: bold;
	color: #030303;
`;

export const ListSubtitle = styled(ListTitle)`
	font-size: 1em;
	font-weight: normal;
	color: #444;
`;