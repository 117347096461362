const DATA = [
  {
    instruction: "Answer - Yes (or) No",
    text: () => "Log Base 10 Equation. Is the equation correct?",
    image: "018-1",
    skipValidation: false,
    answer: "no",
  },
  {
    instruction: "Answer - Yes (or) No",
    text: () => "Log Base 10 Equation. Is the equation correct?",
    image: "018-2",
    skipValidation: false,
    answer: "no",
  },
  {
    instruction: "Answer - Yes (or) No",
    text: () => "Log Base 10 Equation. Is the equation correct?",
    image: "018-3",
    skipValidation: false,
    answer: "no",
  },
  {
    text: () => "What is X?",
    image: "018-4",
    skipValidation: false,
    answer: 10,
  },
  {
    text: () => "What is X?",
    image: "018-5",
    skipValidation: false,
    answer: "10 power 100",
  },
  {
    text: () => "What is X?",
    image: "018-6",
    skipValidation: false,
    answer: "10 power 10000",
  },
]

export default DATA;