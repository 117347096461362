const DATA = [
  {
    instruction: "Please say the formula after your answer. Thanks.",
    text: () => "What can you tell me about the Pythagorean Theorem",
    skipValidation: true,
  },
  {
    text: () => "What is the fundamental theorem of arithmetic?",
    skipValidation: true,
  },
  {
    text: () => "What’s can you tell me about the 2,000,000? Is it a prime number (or) is it a product of prime factors?",
    skipValidation: true,
  },
  {
    text: () => "Which side (a., b., or c.) is adjacent theta?",
    image: "001",
    skipValidation: true,
  },
  {
    text: () => "Which side (a., b., or c.) is opposite theta?",
    image: "001",
    skipValidation: true,
  },
  {
    text: () => "Which side (a., b., or c.) is hypotenuse?",
    image: "001",
    skipValidation: true,
  },
  {
    text: () => "Please answer the following questions as numbers. Try to use your head but use scratch paper if you must.",
    skipValidation: true,
  },
  {
    text: () => "What is the cubed root of 125?",
    skipValidation: false,
    answer: 5
  },
  {
    text: () => "How long is the hypotenuse?",
    image: "002-2",
    skipValidation: false,
    answer: 10,
  },
  {
    text: () => "What’s the perimeter of this triangle?",
    image: "002-2",
    skipValidation: false,
    answer: 24,
  },
  {
    instruction: "You don’t need to calculate the answer - just tell the formula",
    text: () => "What formula would you use to get the circumference of the circle below ?",
    image: "006-1",
    skipValidation: true,
  },
  {
    text: () => "What is the answer?",
    image: "007",
    skipValidation: false,
    answer: 1,
  },
  {
    text: () => "What is the answer?",
    image: "009-1",
    skipValidation: false,
    answer: 1000000,
  },
  {
    text: () => "What is the answer?",
    image: "010-1",
    skipValidation: false,
    answer: 25,
  },
  {
    text: () => "What is the answer?",
    image: "010-2",
    skipValidation: false,
    answer: 125,
  },
  {
    instruction: "Decimals only and no fraction",
    text: () => "What is the answer?",
    image: "011",
    skipValidation: false,
    answer: 0.001,
  },
  {
    text: () => "What is X?",
    image: "014-2",
    skipValidation: false,
    answer: 5,
  },
  {
    text: () => "What is X?",
    image: "017",
    skipValidation: false,
    answer: 215,
  },
  {
    text: () => "What is the answer?",
    image: "019",
    skipValidation: false,
    answer: 435,
  },
  {
    text: () => "What is the answer?",
    image: "020",
    skipValidation: false,
    answer: 108,
  },
  {
    text: () => "What is the answer?",
    image: "021",
    skipValidation: false,
    answer: 7,
  },
  {
    instruction: "Answer in the following format \“1 by 4\”",
    text: () => "What is the answer?",
    image: "022-2",
    skipValidation: false,
    answer: "13/20",
  },
]

export default DATA;