import styled from 'styled-components';

export const RootContainer = styled.div`
	background: #E0EAFC;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #CFDEF3, #E0EAFC);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #CFDEF3, #E0EAFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Title = styled.h1`
	color: ${({theme}) => theme.colors.black};
	text-align: center;
`;

export const Subtitle = styled.h2`
	color: ${({theme}) => theme.colors.lightGrey};
	text-align: center;
`;

export const InstructionsContainer = styled.div`
	width: 70vw;
	flex: 0.5;
	padding: 10px 30px;
	background: ${({theme}) => theme.colors.white};
	box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	overflow-y: scroll;
`;

export const LibraryCard = styled.div`
	padding: 20px;
	margin: 10px 20px;
	cursor: pointer;
	background: ${({theme}) => theme.colors.white};
	box-shadow: 4px 4px 16px rgba(0,0,0,0.25);
`;