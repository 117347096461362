import { useEffect, useState, useMemo } from "react";
import {
  RootContainer,
  Header,
  HeaderImage,
  MainImage,
  TextContainer,
  OriginalSpan,
  OriginalSpan1,
  ReadedText,
  YourAnswerTitle,
  YourAnswer,
  SubHeading
} from './Interview.styles';
import PuffLoader from 'react-spinners/PuffLoader';
import Logo from 'assets/images/brainsprays_logo400.png';
import Shake from 'react-reveal/Shake';
import {useNavigate, useParams} from 'react-router-dom';
import {PrimaryButton, VerticalSpace, HorizontalSpace, Modal} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';
import {GrPowerReset} from 'react-icons/gr'
import {animated, useTransition} from 'react-spring';
import useSpeechToText from 'react-hook-speech-to-text';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import useRecorder from "utils/useRecorder";
import wordsToNumbers from 'words-to-numbers';
import challenges from 'assets/challenges';

const Interview = () => {
  const navigate = useNavigate()
  const {index} = useParams()
  const DATA = challenges[index].question
  const dispatch = useDispatch()
  const {accountId} = useSelector(state => state.user, shallowEqual)
  const [answer, setAnswer] = useState('');
  const [buttonColor, setButtonColor] = useState('grey');
  const [currentOriginalText, setCurrentOriginalText] = useState(0)
  const [currentWrongAttempt, setCurrentWrongAttempt] = useState(0)
  const [currentReadedText, setCurrentReadedText] = useState('')
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(true)
  const [showConfetti, setShowConfetti] = useState(false)
  const [replacedText, setReplacedText] = useState(DATA[0].text)
  let [audioURL, isVoiceRecording, startRecording, stopRecording] = useRecorder();

  const {
      error,
      isRecording,
      interimResult,
      results,
      setResults,
      startSpeechToText,
      stopSpeechToText,
    } = useSpeechToText({
      // continuous: false,
      continuous: true,
      crossBrowser: true,
      googleApiKey: "AIzaSyBgItgnqpBRRFpdZ_tQRQW3D9EvIl0emUE",
      googleCloudRecognitionConfig: {
        languageCode: 'en-US'
      },
      useLegacyResults: false,
      // timeout: 3000
  });

  const afterUpdatingAnswer = () => {
    if(currentOriginalText === DATA.length-1) {
      stopRecording()
    }
    else {
      setCurrentOriginalText(p => p+1);
      setCurrentWrongAttempt(0)
      setResults([])
    }
  }

  useEffect(() => {
      if(!accountId) {
        navigate('/')
      }
      else {
        startSpeechToText()
        startRecording()
      }
     // setReplacedText(DATA[0].text)
  }, [])

  const nextQuestion = () => {
    const object = {questionNumber: currentOriginalText+1, question: DATA[currentOriginalText]?.text?DATA[currentOriginalText]?.text():`Question Number ${currentOriginalText+1}`, answer: answer?`${isCorrectAnswer?'':'**Wrong Answer** '}${answer}`:"**Question Skipped**"}
    dispatch(userActions.updateAnswerAction.action(object))
    if(currentOriginalText === DATA?.length-1) {
      stopRecording()
      return null;
    }
    setIsCorrectAnswer(true)
    setCurrentReadedText([])
    setButtonColor('grey')
    setAnswer('')
    setCurrentOriginalText(p => p+1)
  }

  useEffect(() => {
    // console.log("RESULTS", results, DATA[currentOriginalText])
    if(results && results.length) {
      if(DATA[currentOriginalText].skipValidation) {
        setIsCorrectAnswer(true)
        setButtonColor('orange')
      }
      else {
        // console.log("VALIDATE ANSWER", DATA[currentOriginalText].answer.toString(), wordsToNumbers(results[0].transcript.trim()))
        if(DATA[currentOriginalText].answer.toString().trim() === wordsToNumbers(results[0].transcript.trim()).toString() || wordsToNumbers(results[0].transcript.trim()).toString().includes(DATA[currentOriginalText].answer.toString().trim())) {
          setIsCorrectAnswer(true)
          setButtonColor('green')
        }
        else {
          setIsCorrectAnswer(false)
          setButtonColor('black')
        }
      }
      setResults([])
      setAnswer(p => p+' '+results[0].transcript)
    }
  }, [results])

  return (
    <RootContainer>
      {showConfetti && <ConfettiExplosion duration={1000} force={0.6} particleCount={50} floorHeight={1600} floorWidth={1600} />}
      <Header>
        <HeaderImage src={Logo} />
        <PrimaryButton onClick={() => {stopSpeechToText(); navigate('/dashboard')}} >Exit To Dashboard</PrimaryButton>
      </Header>
      <VerticalSpace count={3} />
      {
        isRecording
        ?
        <TextContainer>
          <PuffLoader size={30} />
          <HorizontalSpace count={2} />
          <YourAnswer>✅ Listening now (<strong>Speak aloud</strong>) ...</YourAnswer>
        </TextContainer>
        :
        <TextContainer>
          <YourAnswer onClick={() => startSpeechToText()} >❌ Not listening! Click here to listen again.</YourAnswer>                            
        </TextContainer>   
      }
      <VerticalSpace count={1} />
      <SubHeading>Note - If not listening, check your browser "Microphone" permissions (<a href="https://shorturl.at/loBCI" target="_blank">Reference article</a>)</SubHeading>
      <VerticalSpace count={10} />
      <TextContainer>
        <OriginalSpan1>{currentOriginalText+1}. {DATA[currentOriginalText].text()}</OriginalSpan1>
      </TextContainer>
      <VerticalSpace count={3} />
      {
         DATA[currentOriginalText].instruction
         ?
         <YourAnswer><strong>{DATA[currentOriginalText].instruction}</strong></YourAnswer>
         :
         null
      }
      <VerticalSpace count={2} />
      {
        DATA[currentOriginalText].image
        ?
        <MainImage src={`${process.env.PUBLIC_URL}/images/${DATA[currentOriginalText].image}.png`} />
        :
        null
      }
      <VerticalSpace count={3} />
      <TextContainer>
        <PrimaryButton background={buttonColor} onClick={nextQuestion} >{currentOriginalText >= DATA.length-1 ? "Submit" : "Next Question →" }</PrimaryButton>
      </TextContainer>
    </RootContainer>
  )
}

export default Interview;
