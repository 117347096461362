const DATA = [
  {
   text: () => "What can you tell me about the interior angles of a triangle?",
   skipValidation: true,
  },
  {
   text: () => "What can you tell me about the interior angles of a square?",
   skipValidation: true,
  },
  {
    text: () => "Please answer the following questions as numbers. Try to use your head but use scratch paper if you must.",
    skipValidation: true,
  },
  {
    instruction: "Number only",
    text: () => "If Angle B = 45 deg., what does Angle A equal (in deg.)?",
    image: "003",
    skipValidation: false,
    answer: 45,
  },
  {
    instruction: "Number only",
    text: () => "If Angle B = 30 deg., what does Angle A equal (in deg.)?",
    image: "004",
    skipValidation: false,
    answer: 60,
  },
  {
    text: () => "What’s the perimeter of this triangle?",
    image: "002-1",
    skipValidation: false,
    answer: 120,
  },
  {
    text: () => "What is the cubed root of 216?",
    skipValidation: false,
    answer: 6
  },
  {
    text: () => "What is the cubed root of 1,000,000?",
    skipValidation: false,
    answer: 100
  },
  {
    instruction: "Answer can include \“pi\”",
    text: () => "What’s the circumference of the circle below?",
    image: "006-1",
    skipValidation: true,
  },
  {
    instruction: "Answer can include \“pi\”",
    text: () => "What’s the area of the circle below?",
    image: "006-1",
    skipValidation: true,
  },
  {
    instruction: "Number only. Exclude \“degrees\”",
    text: () => "How Many Equal Degrees Are In The Circle Below?",
    image: "006-2",
    skipValidation: false,
    answer: 360,
  },
  {
    instruction: "Number only. Exclude \“pi\”",
    text: () => "How Many Radians Are In The Circle Below?",
    image: "006-2",
    skipValidation: false,
    answer: 2,
  },
  {
    instruction: "Number only. Exclude \“pi\”",
    text: () => "How Many Radians Are In Half The Circle Below?",
    image: "006-3",
    skipValidation: false,
    answer: 1,
  },
  {
    instruction: "Number only. Exclude \“degrees\”",
    text: () => "How Many Degrees Are In One Quarter of The Circle Below?",
    image: "006-4",
    skipValidation: false,
    answer: 90,
  },
  {
    instruction: "Answer in the following format \“1 by 4\”. Answer can include \“pi\”",
    text: () => "How Many Radians Are In One Quarter of The Circle Below?",
    image: "006-4",
    skipValidation: true,
    // answer: "pi by 2",
  },
  {
    text: () => "What is the answer?",
    image: "007",
    skipValidation: false,
    answer: 1,
  },
  {
    text: () => "What is the answer?",
    image: "008",
    skipValidation: false,
    answer: 100000,
  },
  {
    text: () => "What is the answer?",
    image: "009-1",
    skipValidation: false,
    answer: 1000000,
  },
  {
    text: () => "What is the answer?",
    image: "009-3",
    skipValidation: false,
    answer: 100,
  },
  {
    text: () => "What is the answer?",
    image: "009-4",
    skipValidation: false,
    answer: 1000,
  },
  {
    instruction: "Answer - Yes (or) No",
    text: () => "Log Base 10 Equation. Is the equation correct?",
    image: "018-1",
    skipValidation: false,
    answer: "no",
  },
  {
    instruction: "Answer - Yes (or) No",
    text: () => "Log Base 10 Equation. Is the equation correct?",
    image: "018-2",
    skipValidation: false,
    answer: "no",
  },
  {
    instruction: "Answer - Yes (or) No",
    text: () => "Log Base 10 Equation. Is the equation correct?",
    image: "018-3",
    skipValidation: false,
    answer: "no",
  },
  {
    text: () => "What is X?",
    image: "018-4",
    skipValidation: false,
    answer: 10,
  },
  {
    text: () => "What is X?",
    image: "018-5",
    skipValidation: false,
    answer: "10 power 100",
  },
  {
    text: () => "What is X?",
    image: "018-6",
    skipValidation: false,
    answer: "10 power 10000",
  },
  {
    text: () => "What is X?",
    image: "018-7",
    skipValidation: false,
    answer: 0,
  },
  {
    text: () => "What is X?",
    image: "018-8",
    skipValidation: false,
    answer: 2,
  },
  {
    text: () => "What is X?",
    image: "018-9",
    skipValidation: false,
    answer: 4,
  },
  {
    text: () => "What is the answer?",
    image: "013-8",
    skipValidation: true,
    answer: 6^81 * 10,
  },
  {
    text: () => "What is the answer?",
    image: "013-9",
    skipValidation: true,
    answer: 5^3, //answer: 125
  },
  {
    text: () => "What is X?",
    image: "013-2",
    skipValidation: false,
    answer: 12,
  },
  {
    text: () => "What is X?",
    image: "013-3",
    skipValidation: false,
    answer: 3,
  },
  {
    text: () => "What is X?",
    image: "013-4",
    skipValidation: false,
    answer: 24,
  },
  {
    text: () => "What is X?",
    image: "014-1",
    skipValidation: false,
    answer: 5,
  },
  {
    text: () => "What is X?",
    image: "015",
    skipValidation: false,
    answer: 5,
  },
  {
    text: () => "What is X?",
    image: "016",
    skipValidation: false,
    answer: 5,
  },
  {
    text: () => "What is X?",
    image: "017",
    skipValidation: false,
    answer: 215,
  },
  {
    text: () => "What is the answer?",
    image: "019",
    skipValidation: false,
    answer: 435,
  },
  {
    instruction: "Answer in the following format \“1 by 4\”",
    text: () => "What is the answer?",
    image: "022-1",
    skipValidation: false,
    answer: "7/12",
  },
]

export default DATA;