import {useState, useEffect} from 'react';
import {PrimaryButton, VerticalSpace, HorizontalSpace} from 'components';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';

import {
	RootContainer,
	ParticipantWrapper,
	ParticipantContainer,
	ParticipantEmail,
	ParticipantName,
	InstructionWrapper,
	InstructionsHeading,
	InstructionsContainer,
	InstructionList,
	InstructionListItem,
	ButtonBar,
} from './Instructions.styles';

const Instructions = () => {
	const {index} = useParams()
	const navigate = useNavigate()
	const {profile, accountId, action} = useSelector(state => state.user, shallowEqual)

	const onProceedClick = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
			if(stream) {
				navigate('/interview/'+index)
			}
		}
		catch(err) {
			console.log("ERROR", err)
		}
	}

	useEffect(() => {
		if(!accountId) {
		  navigate('/')
		}
	}, [])

	return (
		<RootContainer>
			<ParticipantWrapper>
				<ParticipantContainer>
					<ParticipantName>
						Welcome, {profile.name}
					</ParticipantName>
				</ParticipantContainer>
			</ParticipantWrapper>
			<VerticalSpace count={2} />
			<InstructionWrapper>
				<InstructionsHeading>Math Practice Instructions</InstructionsHeading>
				<InstructionsContainer>
					<InstructionList>
						<InstructionListItem>
							6 - 40 questions. <b>Don't read the questions aloud. Only say the answers out loud</b>.
						</InstructionListItem>
						<InstructionListItem>
							You need to provide audio permissions for us to listen and record the session.
						</InstructionListItem>
						<InstructionListItem>
							Start answering out loud once "<i><u>✅ Listening now (Speak aloud) ...</u></i>" shows above the question (at the top).
						</InstructionListItem>
						<InstructionListItem>
							If you see "<i><u>❌ Not listening. Click here to listen</u></i>" message shows above the question (at the top). Click on it for the app to start listening.
						</InstructionListItem>
						<InstructionListItem>
							Button color code -
							<ul>
								<li><font color="#333"><b>Next sentence</b></font> = Default button color</li>
								<li><font color="#679436"><b>Next sentence</b></font> = Correct</li>
								<li><font color="#FFA500"><b>Next sentence</b></font> = Could be correct</li>
								<li><font color="#000"><b>Next sentence</b></font> = Incorrect</li>
							</ul>
						</InstructionListItem>
						<InstructionListItem>
							Please don't refresh the page or go back! Your session will be lost.
						</InstructionListItem>
						<InstructionListItem>
							That's all you need to get started. <b>Good luck!</b>
						</InstructionListItem>
					</InstructionList>
				</InstructionsContainer>
				<ButtonBar>
			{/*		<PrimaryButton background="red" >No, I'm out of this</PrimaryButton>
					<HorizontalSpace count={2} />*/}
					<PrimaryButton onClick={onProceedClick} >Start →</PrimaryButton>
				</ButtonBar>
			</InstructionWrapper>
		</RootContainer>
	)
}

export default Instructions;
